<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ReceiptsTable filters="true" />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ReceiptsTable from './elements/receiptsTable.vue'

export default {
  name: 'Receipts',
  components: { ReceiptsTable }
}
</script>
