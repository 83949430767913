var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.events)?_c('CToaster',[_c('CToast',{attrs:{"show":!!_vm.events.event,"color":"info"}},[_vm._v(" "+_vm._s(_vm.$t('Generating receipt'))+": "+_vm._s(_vm.events.event)+" ")])],1):_vm._e(),_c('CModal',{attrs:{"show":_vm.modalEmail.show,"centered":true,"title":_vm.$t('Email control'),"size":"lg"},on:{"update:show":function($event){return _vm.$set(_vm.modalEmail, "show", $event)}},scopedSlots:_vm._u([{key:"footer-wrapper",fn:function(){return [_c('span')]},proxy:true}])},[(_vm.modalEmail.show)?[_c('ModalEmail',{attrs:{"entity":_vm.modalEmail.receipt,"recipients":_vm.modalEmail.recipients,"contact_types":_vm.modalEmail.contact_types,"subject_prefix":"Recibo de Caja de Administración - "},on:{"modalUpdated":_vm.onModalEmailUpdated,"parentHide":function($event){_vm.modalEmail.show = false}}})]:_vm._e()],2),_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
        //'id',
        { key: 'consecutive', label: 'Receipt' },
        _vm.helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        _vm.helpers.building,
        _vm.getHelperObject('building_type', 'building.building_type_id'),
        _vm.getHelperObject('property_unit', 'building.property_unit_id'),
        _vm.helpers.ownerName,
        _vm.helpers.currencyValue,
        Object.assign({}, _vm.helpers.currencyValue, {key: 'discount_value', label: 'with Discount', _hide: !_vm.discount}), // TODO: Show only on month with discounts
        _vm.helpers.createdAt,
        { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 180px;' }
      ],"config":Object.assign({}, {name: 'receipt',
      url: 'admin/receipts' + (this.building_id ? ("?conditions=building_id=" + (this.building_id)) : ''),
      route: '/admin/invoicing/receipts',
      params: Object.assign({}, {_whits: 'early_payment_discount', _lists: 'months,contact_types,building_types,property_units'}, (_vm.params || {})),
      filters: _vm.filters ? [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'building_type', config: { select_all: true } }, { key: 'property_unit', config: { select_all: true } }, 'owner_name'] : [],
      noCreate: true,
      noOptions: true,
      _check: true},
      (_vm.config || {}))},on:{"dataChanged":_vm.onDataChanged,"preparedHelpers":_vm.onPreparedHelpers},scopedSlots:_vm._u([(!_vm.building_id)?{key:"header-button",fn:function(){return [_c('CDropdown',{staticClass:"float-right",attrs:{"color":_vm.checked.length ? 'warning' : 'danger',"toggler-text":_vm.$t('Generate receipts') + " (" + (_vm.month._period_name) + ")" + (_vm.checked.length ? (" [" + (_vm.checked.length) + "]") : '')}},[_c('CDropdownItem',{on:{"click":function($event){return _vm.onGenerateReceipts(false)}}},[_vm._v(_vm._s(_vm.$t('Print')))]),_c('CDropdownItem',{on:{"click":function($event){return _vm.onGenerateReceipts(true)}}},[_vm._v(_vm._s(_vm.$t('Email')))])],1)]},proxy:true}:null,{key:"options",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","white-space":"nowrap"}},[_c('CButton',{staticClass:"mr-2",attrs:{"type":"button","color":"info"},on:{"click":function($event){return _vm.getReceipt('download', item)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1),_c('CButton',{staticClass:"mr-2",attrs:{"type":"button","color":"light"},on:{"click":function($event){return _vm.getReceipt('preview', item)}}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1),_c('CButton',{staticClass:"mr-2",attrs:{"type":"button","color":"danger"},on:{"click":function($event){return _vm.onEmailReceipt(item)}}},[_c('CIcon',{attrs:{"name":"cil-envelope-closed"}})],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }